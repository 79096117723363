import camelcaseKeys from 'camelcase-keys';
import { EventType } from '#types/eventBus';
import { evaluateLoginMethods } from '#utils/evaluateLoginMethods';
import { getIdentityProviderUrl } from '#utils/getIdentityProviderUrl';
import { preloadBackgroundImg } from '#utils/image';
import { preloadRequest } from '#utils/preloadRequest';
const parseResponseData = url => fetch(url).then(data => data.json()).then(preferencesData => camelcaseKeys(preferencesData, {
  deep: true
}));
const handleLoginMethods = loginMethods => {
  if (evaluateLoginMethods(loginMethods).shouldRedirectToSingleIdProvider) {
    const [{
      id
    }] = loginMethods.identityProviders ?? [];
    if (id) {
      window.location.href = getIdentityProviderUrl(id);
    }
  }
};
const preloadMainRequests = async () => {
  preloadRequest({
    readyEvent: EventType.READY_GET_PREFERENCES,
    loadedEvent: EventType.PREFERENCES_LOADED,
    loadData: () => parseResponseData('/api/v1/login/preferences')
  }).then(preloadBackgroundImg);
  preloadRequest({
    readyEvent: EventType.READY_GET_LOGIN_METHODS,
    loadedEvent: EventType.LOGIN_METHODS_LOADED,
    loadData: () => parseResponseData('/api/v1/login/methods')
  }).then(handleLoginMethods);
};
preloadMainRequests();