import { GitSbercloudTechIamAppApiIamAuthClientHttpLoginPreferencesTheme } from '@sbercloud/typings-iam-auth-client';
import { CSS_BREAKPOINTS } from '@sbercloud/uikit-product-utils';
import BootcampPNG from '#assets/bootcamp/background.png';
import BootcampWebP from '#assets/bootcamp/background.webp';
import CloudPNG from '#assets/cloud/background.png';
import CloudWebP from '#assets/cloud/background.webp';
import GitversePNG from '#assets/gitverse/background.png';
import GitverseWebP from '#assets/gitverse/background.webp';
import { supportWebP } from './supportWebP';
export const injectPreloadLink = href => {
  const head = document.querySelector('head');
  if (!head) {
    return;
  }
  const link = document.createElement('link');
  link.rel = 'preload';
  link.as = 'image';
  link.href = href;
  head.appendChild(link);
};
export const preloadBackgroundImg = async preferences => {
  const withoutBackgroundImage = matchMedia(CSS_BREAKPOINTS.tablet).matches;
  if (withoutBackgroundImage) {
    return;
  }
  const isSupportWebP = supportWebP();
  let defaultBackground = '';
  switch (preferences.theme) {
    case GitSbercloudTechIamAppApiIamAuthClientHttpLoginPreferencesTheme.Bootcamp:
      defaultBackground = isSupportWebP ? BootcampWebP : BootcampPNG;
      break;
    case GitSbercloudTechIamAppApiIamAuthClientHttpLoginPreferencesTheme.Gitverse:
      defaultBackground = isSupportWebP ? GitverseWebP : GitversePNG;
      break;
    default:
      defaultBackground = isSupportWebP ? CloudWebP : CloudPNG;
      break;
  }
  injectPreloadLink(defaultBackground);
};