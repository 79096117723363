import { eventBus } from '#constants/eventBus';
import { subscribeToEvent } from './eventBus';
export const preloadRequest = async _ref => {
  let {
    loadedEvent,
    readyEvent,
    loadData
  } = _ref;
  let loadedData = null;
  let dataWasSent = false;
  let recipientDataReady = false;
  const sendData = () => {
    if (!dataWasSent) {
      dataWasSent = true;
      eventBus.next({
        type: loadedEvent,
        payload: loadedData
      });
    }
  };
  subscribeToEvent(eventBus, readyEvent, () => {
    if (loadedData) {
      sendData();
    } else {
      recipientDataReady = true;
    }
  });
  loadedData = await loadData();
  if (recipientDataReady) {
    sendData();
  }
  return loadedData;
};