import { BASENAME } from '#constants/basename';
import { RoutePath } from '#constants/routes';
export const evaluateLoginMethods = loginMethods => {
  const {
    customerSso,
    passkey,
    password,
    identityProviders = []
  } = loginMethods ?? {};
  const isErrorPage = location.pathname === `${BASENAME}${RoutePath.ErrorPage}`;
  return {
    isAnyLoginMethodAvailable: customerSso || passkey || password || identityProviders.length > 0,
    shouldRedirectToSingleIdProvider: !isErrorPage && !customerSso && !passkey && !password && identityProviders.length === 1
  };
};